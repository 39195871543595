<template>
  <div :class="['ui-textarea', { error: error, required: requiredField }]">
    <label :for="label" class="ui-textarea__label">{{  error ? errorText || label : label }}</label>
    <ui-button color="error" class="ui-textarea__send" @click="$emit('send')" v-if="showSend" :disabled="!value.length">
      <img src="@/assets/svg/icon-send-white.svg" alt="">
    </ui-button>
    <textarea
        :id="label"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        :style="{ 'height': height + 'px','borderRadius': radius + 'px' }"
    />
  </div>
</template>

<script>
export default {
  name: "UiTextarea",
  components: {
    UiButton: () => import('@/components/ui/UiButton'),
  },
  props: {
    value: String,
    placeholder: String,
    error: {
      type: Boolean,
      default: false,
    },
    errorText: String,
    label: String,
    requiredField: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: 40
    },
    radius: {
      type: [String, Number],
      default: 200
    },
    showSend: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  textarea {
    width: 100%;
    border: 1px solid #9A9A9A;
    background: transparent;
    padding: 14px 33px 13px 20px;
    font-weight: 400;
    color: #343432;
    outline: none;
    transition: 0.25s;
    resize: none;
    font-size: 12px;
    line-height: 13px;

    &::placeholder {
      color: #9A9A9A;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.error {
    .ui-textarea {

      &__label {
        animation: 0.25s shake;
        color: #E21F1F;
      }
    }
    textarea {
      border: 1px solid #E21F1F;
    }
  }

  &.required {

    .ui-textarea__label:after {
      content: '*';
      color: #E21F1F;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin: 0 0 0 30px;
    transition: 0.25s;
  }

  &__send {
    position: absolute;
    right: 4px;
    top: 14px;
    cursor: pointer;
    width: 32px !important;
    height: 32px;
  }
}
</style>
